import moment from "moment";

export const formatTime = (time: string) => {
    return moment.utc(time).format("HH:mm:ss");
};

export const formatDate = (date: string) => {
    return moment.utc(date).format("DD/MM/YYYY");
};

export const formatDateTime = (date: string) => {
    return moment.utc(date).format("DD/MM/YYYY HH:mm");
};

export const diffTime = (start: string, end: string) => {
    const diffMillis = new Date(end).getTime() - new Date(start).getTime();
    return millisToMinutesAndSeconds(diffMillis);
};

export const millisToMinutesAndSeconds = (millis: number) => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (parseInt(seconds) < 10 ? '0' : '') + seconds;
}

//DD/MES/YYYY
export const formatDate2 = (isoString: string) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('pt-BR', { day: '2-digit', month: 'long', year: 'numeric' });
    const formattedDateClean = formattedDate.replace(/ de /g, ' / ');

    return formattedDateClean;
};